import React from 'react';

const SecurityStandardsSection = () => {
  const standards = [
    { icon: '/assets/OWASP10.f86f9709.svg', title: 'OWASP 10' },
    { icon: '/assets/spider.svg', title: 'SANS25' },
    { icon: '/assets/OSSTMM.0e2a7e94.svg', title: 'OSSTMM' },
    { icon: '/assets/NIST  SP800-115.605026b0.svg', title: 'NIST SP800-115' },
    { icon: '/assets/CERT-in.45c35a88.svg', title: 'CERT-in' },
    { icon: '/assets/CIS -Benchmark.56d8f6ef.svg', title: 'CIS Benchmark' }
  ];

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
  };

  return (
    <div className="px-8 my-4 bg-gray-50 mx-auto">
      <div className="text-center mb-12">
        <h2 className="text-3xl font-bold mb-4">Industry's Best Security Standards</h2>
        <p className="text-lg text-gray-600 mb-6">
          Security Monks security experts practice the industry's best security standards.
        </p>
        <button onClick={scrollToTop} className="bg-orange-500 text-white px-6 py-2 rounded-full font-semibold">
          Get a FREE Security Consultation
        </button>
      </div>
      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6">
        {standards.map((standard, index) => (
          <div key={index} className="flex flex-col items-center bg-white p-6 rounded-lg shadow-md">
            <img src={standard.icon} alt={standard.title} className="w-16 h-16 mb-4" />
            <h3 className="text-xl font-semibold">{standard.title}</h3>
          </div>
        ))}
      </div>
    </div>
  );
};

export default SecurityStandardsSection;
