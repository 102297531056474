import React from 'react';

const ServicesSection = () => {
  const services = [
    {
      icon: '/assets/Mobile-Application.fa37e775.svg',
      title: 'Application Penetration Testing',
      description: 'Helps detect the vulnerabilities present in a Web/Mobile application’s framework.'
    },
    {
      icon: '/assets/Network penetration.99b6da38.svg',
      title: 'Network Penetration Testing',
      description: 'Assesses and detects vulnerabilities present across the network of an organization.'
    },
    {
      icon: '/assets/Cloud Penetration.8152b5b1.svg',
      title: 'Cloud Penetration Testing',
      description: 'Evaluates a cloud system’s durability and flaws to maintain its framework’s security.'
    },
    {
      icon: '/assets/IOT.475a86cf.svg',
      title: 'IoT Penetration Testing',
      description: 'Secures internet or network based devices to ensure their appropriate functionality.'
    },
    {
      icon: '/assets/Medical Device.f7241e28.svg',
      title: 'Medical Device Penetration Testing',
      description: 'Ensures safety of specialized hospital information systems and IoT medical devices.'
    },
    {
      icon: '/assets/Secure Code .d90e1a9c.svg',
      title: 'Secure Code Review',
      description: 'Manual or automated evaluation of an application’s source code to detect vulnerabilities.'
    }
  ];

  return (
    <div className="px-8 mt-16 mb-4 mx-auto">
        <div className="text-center mb-12">
          <h2 className="text-3xl font-bold mb-4">Experience Security Monks Services</h2>
          <p className="text-lg text-gray-600 mb-6">
            Security Monks provides a complete suite of manual and automated <span className="font-semibold">security testing services.</span>
          </p>
        </div>
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6">
          {services.map((service, index) => (
            <div key={index} className="flex flex-col items-center text-center p-6 bg-gray-100 rounded-lg shadow-md">
              <img src={service.icon} alt={service.title} className="w-16 h-16 mb-4" />
              <h3 className="text-xl font-semibold mb-2">{service.title}</h3>
              <p className="text-gray-600">{service.description}</p>
            </div>
          ))}
        </div>
        <div className="text-center mt-12">
          <p className="text-gray-600 text-justify">
            With us, organizations need not buy expensive security penetration testing software as our services are developed and delivered to strengthen security and add monetary value to your enterprise. Our security experts also provide cybersecurity consulting services to help businesses grow.
          </p>
        </div>
    </div>
  );
};

export default ServicesSection;
