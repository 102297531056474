import React from 'react';

const HeroSection = () => {
  return (
    <div className="relative flex flex-col lg:flex-row bg-gray-900 text-white p-8 lg:p-16">
      <div className="lg:w-2/3 w-full">
        <img
          src="/assets/banner.59bac630.webp"
          alt="Background"
          className="absolute inset-0 w-full h-full object-cover opacity-30"
        />
        <div className="relative z-10 text-center md:text-left md:mr-8">
          <img src="/assets/SecurityMonks.png" alt="Security Monks Logo" className="w-32 mx-auto md:mx-0 md:w-44" />
          <h2 className="text-3xl lg:text-5xl font-bold mb-4">Be Cybersecure With Security Monks VAPT Services</h2>
          <p className="mb-8">Reduce the prospect of cyber threats with Vulnerability Assessment Penetration Testing!</p>
          <div className="flex flex-col lg:flex-row lg:space-x-8">
            <div className="text-center mb-4 lg:mb-0">
              <p className="text-xl lg:text-3xl font-bold text-orange-500">16 k+</p>
              <p>Applications & Devices Tested</p>
            </div>
            <div className="text-center mb-4 lg:mb-0">
              <p className="text-xl lg:text-3xl font-bold text-orange-500">1000 +</p>
              <p>Weeks Of Testing Experience</p>
            </div>
            <div className="text-center">
              <p className="text-xl lg:text-3xl font-bold text-orange-500">100 Mn+</p>
              <p>Lines Of Code Tested</p>
            </div>
          </div>
        </div>
      </div>
      <div className="lg:w-1/3 w-full mt-8 lg:mt-0 bg-white text-black p-8 rounded-lg shadow-lg z-10">
        <h3 className="text-xl lg:text-2xl font-bold mb-4">Get A <span className="text-orange-500">Free</span> Security Consultation</h3>
        <form className="space-y-4" action="mailto:info@thesecuritymonks.com" method="post" encType="text/plain">
          <div>
            <label className="block text-sm font-medium mb-1">Full Name</label>
            <input type="text" name="fullName" className="w-full border border-gray-300 rounded-md p-2 focus:border-orange-500 focus:ring-orange-500" />
          </div>
          <div>
            <label className="block text-sm font-medium mb-1">Business Email</label>
            <input type="email" name="businessEmail" className="w-full border border-gray-300 rounded-md p-2 focus:border-orange-500 focus:ring-orange-500" />
          </div>
          <div>
            <label className="block text-sm font-medium mb-1">Mobile</label>
            <input type="text" name="mobile" className="w-full border border-gray-300 rounded-md p-2 focus:border-orange-500 focus:ring-orange-500" />
          </div>
          <div>
            <label className="block text-sm font-medium mb-1">Company</label>
            <input type="text" name="company" className="w-full border border-gray-300 rounded-md p-2 focus:border-orange-500 focus:ring-orange-500" />
          </div>
          <button type="submit" className="w-full bg-orange-500 text-white p-2 rounded-md hover:bg-orange-600">Submit</button>
        </form>
      </div>
    </div>
  );
};

export default HeroSection;
