import React from 'react';

const IndustriesWeServe = () => {
    const industries = [
        'IT/Consulting', 'Manufacturing', 'Fintech', 'BFSI',
        'NBFC', 'SaaS', 'Healthcare', 'Government',
        'Consumer Internet', 'Human Resources'
    ];

    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        });
    };

    return (
        <div className="my-8 md:mt-0 px-8 bg-white">
            <div className="grid grid-cols-1 md:grid-cols-2 gap-8 items-center">
                <div className='text-center md:text-left'>
                    <h2 className="text-3xl font-bold mb-4">Industries we serve</h2>
                    <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
                        {industries.map((industry, index) => (
                            <div key={index} className="flex items-center space-x-2">
                                <svg
                                    className="h-5 w-5 text-green-500"
                                    fill="none"
                                    stroke="currentColor"
                                    viewBox="0 0 24 24"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7"></path>
                                </svg>
                                <span>{industry}</span>
                            </div>
                        ))}
                    </div>
                    <button onClick={scrollToTop} className="mt-6 px-6 py-2 bg-orange-500 text-white rounded-full shadow-md hover:bg-orange-600 focus:outline-none focus:ring-2 focus:ring-orange-500 focus:ring-opacity-50">
                        Get a Proposal
                    </button>
                </div>
                <div className="md:p-20">
                    <img
                        src="/assets/Industries-we-serve.webp"
                        alt="Trusted"
                    />

                </div>
            </div>
        </div>
    );
};

export default IndustriesWeServe;