import React, { useState } from 'react';

const FAQ = () => {
  const faqs = [
    { id: 1, question: 'What is VAPT?', answer: 'Vulnerability Assessment and Penetration Testing (VAPT) is a security testing methodology that combines two specific techniques within the same area of focus. The testing identifies exploitable security vulnerabilities in your organizations IT infrastructure.' },
    { id: 2, question: 'Do I still need VAPT if my data is stored in the cloud?', answer: 'Your assets may have been moved to the cloud, but that doesn’t guarantee 100% security of your data. Cloud Penetration Testing services are crucial for the assurance of a secure cloud environment, systems and services.' },
    { id: 3, question: 'How frequently should we conduct VAPT?', answer: 'VAPT should be conducted frequently according to the internal changes or compliance and regulatory requirements. Some organizations may perform VAPT once a year, while others may carry it out on a monthly basis.' },
    { id: 4, question: 'How does VAPT protect against data breaches?', answer: 'VAPT is the best way to ensure the security of an organization’s vulnerable assets. By uncovering the hidden security vulnerabilities present in a network or an application, possible cyber attacks can be prevented.' },
    // Add more FAQs as needed
  ];

  const [openId, setOpenId] = useState(null);

  const toggleAnswer = id => {
    setOpenId(openId === id ? null : id);
  };

  return (
    <section className="bg-gray-100 py-8">
      <div className="container mx-auto">
        <h2 className="text-2xl font-bold text-center mb-4">FAQs</h2>
        <div className="space-y-4 px-8">
          {faqs.map(faq => (
            <div key={faq.id} className="bg-white rounded-lg shadow-md p-4">
              <div
                className="cursor-pointer flex justify-between items-center"
                onClick={() => toggleAnswer(faq.id)}
              >
                <h3 className="md:text-lg font-semibold">{faq.question}</h3>
                <svg
                  className={`w-6 h-6 transition-transform transform ${openId === faq.id ? 'rotate-180' : ''}`}
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d={openId === faq.id ? 'M5 15l7-7 7 7' : 'M19 9l-7 7-7-7'}
                  />
                </svg>
              </div>
              {openId === faq.id && <p className="mt-2">{faq.answer}</p>}
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default FAQ;
