import React from 'react';

const Footer = () => {
  return (
    <footer className="bg-gray-800 text-white py-8">
      <div className="container mx-auto flex flex-col items-center justify-center">
        <h2 className="text-xl font-bold mb-4">Contact Us</h2>
        {/* <p className="mb-2">201 optus corporate suites</p>
        <p className="mb-2">Phone: +91 95877 52466</p> */}
        <p>Email: info@thesecuritymonks.com</p>
        {/* <div className="flex space-x-4">
          <a href="#" className="text-white hover:text-gray-400">
            Facebook
          </a>
          <a href="#" className="text-white hover:text-gray-400">
            Twitter
          </a>
          <a href="#" className="text-white hover:text-gray-400">
            Instagram
          </a>
        </div> */}
      </div>
    </footer>
  );
};

export default Footer;
