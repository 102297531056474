import React from 'react';

const WhyUs = () => {
  return (
    <div className="my-8 md:mt-0 px-8 bg-white">
      <div className="grid grid-cols-1 md:grid-cols-2 gap-8 items-center">
        <div className="md:p-20">
            <img
              src="/assets/why-us.webp"
              alt="City"
            />
          
        </div>
        <div>
          <h2 className="text-3xl font-bold mb-4 text-center md:text-left">Why Us?</h2>
          <div className="space-y-4">
            <div className="flex items-center space-x-2">
              <span className="text-orange-500">✔️</span>
              <p className="font-semibold">10k+ No. Of vulnerabilities Detected</p>
            </div>
          </div>
          <p className="mt-4 text-gray-700 text-justify">
            Security Monks is one of the top Cyber Security provider companies known for its state-of-the-art solutions that can seamlessly secure your organization’s IT infrastructure. We have been providing the most effective cyber security solutions to a diverse range of industries including 1825+ SMEs and 150+ enterprise customers across the globe. We offer a complete suite of both automated and manual Vulnerability Assessment and Penetration Testing (VAPT) services which are customized to the requirements of your organization.
          </p>
        </div>
      </div>
    </div>
  );
};

export default WhyUs;
