import './App.css';
import React from 'react';
import HeroSection from './Components/HeroSection';
import WhyUs from './Components/WhyUs';
import SecurityStandardsSection from './Components/SecurityStandardsSection';
import ServicesSection from './Components/ServicesSection';
import IndustriesWeServe from './Components/IndustriesWeServe';
import FAQ from './Components/FAQ';
import Footer from './Components/Footer';

const App = () =>{
  return (
    <div className="App">
      {/* <NavBar/> */}
      <HeroSection/>
      <WhyUs />
      <SecurityStandardsSection />
      <ServicesSection />
      <IndustriesWeServe />
      <FAQ />
      <Footer />
    </div>
  );
}

export default App;
